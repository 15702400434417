import { VStack } from "@chakra-ui/react";

import { AuthMotivationPanel } from "/@/components/AuthMotivationPanel";
import { Navbar } from "/@/components/Navbar";

export const Home: React.FC = () => (
  <VStack align="stretch">
    <Navbar />
    <AuthMotivationPanel />
  </VStack>
);
