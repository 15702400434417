import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Pricing } from "/@/routes/pricing";

import { Home } from "/@/routes/home";

export const App: React.FC = () => {
  return (
    <div className="App">
      <Router basename="/">
        <Routes>
          <Route path="/pricing" element={<Pricing />} />

          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
};
